import React from "react";
import Wrapper from "../Wrapper";
import { graphql } from "gatsby";
import Markdown from "../../UI/Markdown";
import Grid from "../../UI/Grid";
import CustomImage from "../../UI/CustomImage";
import { Box, Heading } from "rebass/styled-components";
const Markup = ({ data }) => {
  return (
    <Wrapper data={data}>
      <Heading as="h2">{data.title}</Heading>
      <Markdown>{data.intro}</Markdown>
      <Grid childWidth={[1, 1 / 2]} alignItems="center">
        <Box>
          <CustomImage img={data.image} />
        </Box>
        <Box>
          <Markdown>{data.profil}</Markdown>
        </Box>
      </Grid>
    </Wrapper>
  );
};
export default Markup;

export const query = graphql`
  fragment AboutBlock on MarkdownRemarkFrontmatterBlockscollection {
    type
    profil
    intro
    title
    image {
      childImageSharp {
        fluid(maxWidth: 400, quality: 64) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      publicURL
    }
  }
`;
