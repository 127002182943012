import PropTypes from "prop-types";
import React from "react";
import CustomImage from "../../UI/CustomImage";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Wrapper from "../Wrapper";
import { Box } from "rebass/styled-components";

const Features = ({ data }) => {
  const features = data.features || [];
  var settings = {
    dots: false,
    autoplaySpeed: 2000,
    arrows: false,
    infinite: true,
    autoplay: false,
    speed: 500,
    slidesToShow: features.length > 4 ? 4 : features.length,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: features.length > 3 ? 3 : features.length,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: features.length > 1 ? 1 : features.length,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <Wrapper data={data}>
      <Slider {...settings}>
        {features.map((feature, i) => (
          <Box p="15px" height="250px" key={"feature-" + i}>
            <h4>{feature.name}</h4>
            <Box>
              <CustomImage img={feature.image} />
            </Box>
          </Box>
        ))}
      </Slider>
    </Wrapper>
  );
};

Features.propTypes = {
  data: PropTypes.shape({
    features: PropTypes.array
  })
};

export default Features;
