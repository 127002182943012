import PropTypes from "prop-types";
import React, { useState } from "react";
import { withTheme } from "styled-components";
import Markdown from "../UI/Markdown";
import { Label, Input, Select, Textarea, Radio, Checkbox } from "@rebass/forms";
import { Box, Text, Button } from "rebass/styled-components";

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};
const isValidEmail = email => {
  var emailRegEx = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  return email.search(emailRegEx) !== -1;
};
const Form = ({ data }) => {
  const [messages, setMessages] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [hasError, setHasError] = useState(false);
  const [sent, setSent] = useState(false);

  if (!data) {
    return (
      <Box>
        <Text>Formulaire Non disponible en previsualisation</Text>
      </Box>
    );
  }
  data = data || { formId: "", elements: [], successMessage: "" };
  const handleChange = (valueName, value) =>
    setFormValues({
      ...formValues,
      [valueName]: value
    });

  const handleSubmit = e => {
    e.preventDefault();
    const newMessages = [];
    var hasTempError = false;

    data.elements.map(function(formElement) {
      var currentValue = formValues[formElement.name] || "";
      if (formElement.required && currentValue === "") {
        hasTempError = true;
        newMessages.push('Le champ "' + formElement.label + '" est requis.');
      }
      if (formElement.type === "text") {
        if (formElement.imputType === "email") {
          if (!isValidEmail(currentValue)) {
            hasTempError = true;
            newMessages.push("Email non valide.");
          }
        }
      }
      return "";
    });
    setMessages(newMessages);
    setHasError(hasTempError);
    if (!hasTempError) {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": data.formId, ...formValues })
      })
        .then(() => submitSuccess())
        .catch(error => alert(error));
    }
  };
  const submitSuccess = () => {
    setFormValues({});
    setMessages([data.successMessage]);
    setSent(true);
    setTimeout(function() {
      setMessages([]);
      setSent(false);
    }, 3000);
  };
  return (
    <Box>
      {data.intro && <Markdown>{data.intro}</Markdown>}
      <form
        data-netlify="true"
        onSubmit={e => {
          handleSubmit(e);
        }}
        name={data.formId}
        id={data.formId}
      >
        <input type="hidden" name="form-name" aria-label="form-name" value={data.formId}></input>
        {!sent && (
          <Box>
            {data.elements.map((formElement, i) => {
              formElement.options = formElement.options || [{ value: "" }];
              return (
                <Box key={"form-el-" + i} py={2}>
                  {formElement.type === "text" && (
                    <>
                      <Label htmlFor={formElement.name}>{formElement.label}</Label>
                      <Input
                        id={formElement.name}
                        name={formElement.name}
                        defaultValue={formElement.default}
                        onChange={e => {
                          handleChange(formElement.name, e.target.value);
                        }}
                      />
                    </>
                  )}
                  {formElement.type === "textarea" && (
                    <>
                      <Label htmlFor={formElement.name}>{formElement.label}</Label>
                      <Textarea
                        id={formElement.name}
                        name={formElement.name}
                        defaultValue={formElement.default}
                        onChange={e => {
                          handleChange(formElement.name, e.target.value);
                        }}
                      />
                    </>
                  )}
                  {formElement.type === "radio" && (
                    <>
                      <Label htmlFor={formElement.name}>{formElement.label}</Label>
                      {formElement.options.map((option, iOption) => (
                        <Label key={iOption}>
                          <Radio
                            id={formElement.name + "-" + iOption}
                            name={formElement.name}
                            value={option.value}
                            onChange={e => {
                              handleChange(
                                formElement.name,
                                e.target.checked ? e.target.value : ""
                              );
                            }}
                            defaultChecked={option.value === formElement.default}
                          />
                          {option.label}
                        </Label>
                      ))}
                    </>
                  )}
                  {formElement.type === "checkbox" && (
                    <>
                      <Label htmlFor={formElement.name}>{formElement.label}</Label>
                      {formElement.options.map((option, iOption) => (
                        <Label key={iOption}>
                          <Checkbox
                            id={formElement.name + "-" + iOption}
                            name={formElement.name}
                            value={option.value}
                            onChange={e => {
                              handleChange(
                                formElement.name,
                                e.target.checked ? e.target.value : ""
                              );
                            }}
                            defaultChecked={option.value === formElement.default}
                          />
                          {option.label}
                        </Label>
                      ))}
                    </>
                  )}
                  {formElement.type === "select" && (
                    <>
                      <Label htmlFor={formElement.name}>{formElement.label}</Label>
                      <Select
                        id={formElement.name}
                        name={formElement.name}
                        defaultValue={formElement.default}
                        onChange={e => {
                          handleChange(
                            formElement.name,
                            e.target.selectedOptions ? e.target.value : ""
                          );
                        }}
                      >
                        {formElement.options.map((optionObj, iOption) => (
                          <option key={iOption} value={optionObj.value}>
                            {optionObj.label}
                          </option>
                        ))}
                      </Select>
                    </>
                  )}
                </Box>
              );
            })}
            <Box py={3}>
              <Button type="submit">Envoyer</Button>
            </Box>
          </Box>
        )}
      </form>
      {messages.length > 0 && (
        <Box bg={hasError ? "danger" : "success"} p={3} color="white">
          {messages.map((message, index) => (
            <Text key={`contact-form-message-${index}`}>{message}</Text>
          ))}
        </Box>
      )}
    </Box>
  );
};

Form.propTypes = {
  data: PropTypes.shape({
    elements: PropTypes.shape({
      map: PropTypes.func
    }),
    formId: PropTypes.string,
    intro: PropTypes.any,
    successMessage: PropTypes.string
  })
};
export default withTheme(Form);
