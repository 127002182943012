import PropTypes from "prop-types";
import React from "react";
import { Box, Flex } from "rebass/styled-components";
import { withTheme } from "styled-components";
const Grid = ({ childWidth, children, ...props }) => {
  return (
    <Flex variant="grid" {...props}>
      {children.map((child, index) => (
        <Box variant="gridItem" width={childWidth} key={`grid-child-${index}`}>
          {child}
        </Box>
      ))}
    </Flex>
  );
};

Grid.propTypes = {
  childWidth: PropTypes.any,
  children: PropTypes.shape({
    map: PropTypes.func
  })
};
export default withTheme(Grid);
