import React from "react";
import { graphql } from "gatsby";
import Markdown from "../../UI/Markdown";
import Wrapper from "../Wrapper";
import { Heading } from "rebass/styled-components";
const Markup = ({ data }) => {
  return (
    <Wrapper data={data}>
      {data.title && <Heading as="h2">{data.title}</Heading>}
      <Markdown>{data.content}</Markdown>
    </Wrapper>
  );
};
export default Markup;

export const query = graphql`
  fragment MarkupBlock on MarkdownRemarkFrontmatterBlockscollection {
    content
    title
  }
`;
