import PropTypes from "prop-types";
import React from "react";
import ReactMarkdown from "react-markdown";
import { graphql } from "gatsby";
import Wrapper from "../Wrapper";
import Grid from "../../UI/Grid";
import CustomImage from "../../UI/CustomImage";
import { Box, Heading } from "rebass/styled-components";

const Expertise = ({ data }) => {
  return (
    <Wrapper data={data}>
      <Heading as="h2">{data.title}</Heading>
      <Grid childWidth={[1, 1 / 2]} alignItems="center">
        <Box>
          <ReactMarkdown source={data.expertise} />
        </Box>
        <Box>
          <CustomImage img={data.expertiseImg} />
        </Box>
      </Grid>
    </Wrapper>
  );
};

Expertise.propTypes = {
  data: PropTypes.shape({
    expertise: PropTypes.any,
    expertiseImg: PropTypes.any,
    title: PropTypes.any
  })
};
export default Expertise;

export const query = graphql`
  fragment ExpertiseBlock on MarkdownRemarkFrontmatterBlockscollection {
    type
    title
    expertise
    expertiseImg {
      childImageSharp {
        fluid(maxWidth: 400, quality: 64) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      publicURL
    }
  }
`;
