import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import Wrapper from "../Wrapper";
import Icofont from "../../UI/Icofont";
import { Box, Heading, Text, Button } from "rebass/styled-components";
import Link from "../../UI/Link";

const Header = ({ data }) => {
  const bgImg = data.blockSettings
    ? data.blockSettings.bgImg
      ? data.blockSettings.bgImg
      : ""
    : "" || "";
  data.ctas = data.ctas || [];

  const bgColor = data.blockSettings
    ? data.blockSettings.bg
      ? data.blockSettings.bg
      : false
    : false || false;
  const sx = {
    backgroundImage: `url(${
      typeof bgImg === "string" ? bgImg : bgImg.childImageSharp.fluid.srcWebp
    })`,
    position: "relative",
    ">*": {
      position: "relative",
      zIndex: 1
    }
  };
  if (bgColor) {
    sx[":before"] = {
      content: '""',
      position: "absolute",
      zIndex: 0,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      width: "100%",
      height: "100%",
      backgroundImage: "radial-gradient(circle, " + bgColor + "99 0%, " + bgColor + "99 100%)"
    };
  }
  return (
    <Wrapper
      data={data}
      sx={sx}
      containerProps={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      display="flex"
    >
      <Box textAlign="center">
        <Heading as="h1">{data.bigTitle}</Heading>
        <Text color="white">{data.subTitle}</Text>
        {data.ctas.map((cta, i) => (
          <Link href={cta.href} key={i}>
            <Button>
              <Icofont icon={cta.icon} />
              {cta.label}
            </Button>
          </Link>
        ))}
      </Box>
    </Wrapper>
  );
};

Header.propTypes = {
  data: PropTypes.shape({
    bigTitle: PropTypes.any,
    blockSettings: PropTypes.shape({
      bg: PropTypes.any,
      bgImg: PropTypes.any
    }),
    ctas: PropTypes.array,
    subTitle: PropTypes.any
  })
};

export default Header;
export const query = graphql`
  fragment HeaderBlock on MarkdownRemarkFrontmatterBlockscollection {
    content
    bigTitle
    subTitle
    bgImg {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 64) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      publicURL
    }
    ctas {
      href
      icon
      label
    }
  }
`;
