import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import Header from "./Header";
import HeroHeader from "./HeroHeader";
import Markup from "./Markup";
import Features from "./Features";
import Gallery from "./Gallery";
import Carousel from "./Carousel";
import About from "./About";
import Numbers from "./Numbers";
import Expertise from "./Expertise";
import Accompagnement from "./Accompagnement";
import Form from "./Form";
import Separator from "./Separator";
import Cards from "./Cards";
import BlockVariant from "./BlockVariant";

const blocksComponents = {
  Header: Header,
  HeroHeader: HeroHeader,
  Markup: Markup,
  Features: Features,
  Gallery: Gallery,
  Carousel: Carousel,
  About: About,
  Numbers: Numbers,
  Expertise: Expertise,
  Accompagnement: Accompagnement,
  Form: Form,
  Separator: Separator,
  Cards: Cards
};

const Block = ({ block }) => {
  const BlockComp = blocksComponents[block.type];
  const Variant = BlockVariant(block);
  if (Variant) {
    return <Variant data={block} />;
  }
  if (BlockComp) {
    return <BlockComp data={block} />;
  }
  return <> </>;
};

Block.propTypes = {
  block: PropTypes.shape({
    type: PropTypes.string
  })
};
export default ({ blocks }) => {
  blocks = blocks || [];
  return blocks.map((blockItem, i) => <Block block={blockItem} key={i} />);
};

export const query = graphql`
  fragment Blocks on MarkdownRemarkFrontmatterBlockscollection {
    type
    blockSettings {
      bg
      color
      containerFluid
      id
      variant
      parallax
      fullHeight
      align
      padding {
        bottom
        top
      }
      bgImg {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 64) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
        publicURL
      }
    }
    ...HeroHeaderBlock
    ...HeaderBlock
    ...MarkupBlock
    ...GalleryBlock
    ...CarouselBlock
    ...AboutBlock
    ...NumbersBlock
    ...ExpertiseBlock
    ...AccompagnementBlock
    ...FormBlock
    ...SeparatorBlock
    ...CardsBlock
  }
`;
