import React from "react";
import ReactMarkdown from "react-markdown";
import { graphql } from "gatsby";
import Wrapper from "../Wrapper";
import Grid from "../../UI/Grid";
import { Box, Heading } from "rebass/styled-components";
import CustomImage from "../../UI/CustomImage";
const Accompagnement = ({ data }) => {
  data.accompagnementItems = data.accompagnementItems || [];
  return (
    <Wrapper data={data}>
      <Heading as="h2">{data.title}</Heading>
      <ReactMarkdown source={data.intro} />
      <Grid childWidth={[1, 1 / 2, 1 / 4]}>
        {data.accompagnementItems.map((item, i) => (
          <Box key={"num-" + i} textAlign="center">
            <Box
              height="200px"
              px={5}
              bg="primary"
              sx={{
                ".gatsby-image-wrapper > div": {
                  paddingBottom: "100% !important" /* your aspect ratio */
                }
              }}
            >
              <CustomImage img={item.image} />
            </Box>
            <ReactMarkdown source={item.content} />
          </Box>
        ))}
      </Grid>
    </Wrapper>
  );
};
export default Accompagnement;

export const query = graphql`
  fragment AccompagnementBlock on MarkdownRemarkFrontmatterBlockscollection {
    type
    title
    intro
    accompagnementItems {
      content
      image {
        childImageSharp {
          fluid(maxWidth: 200, quality: 64) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
        publicURL
      }
    }
  }
`;
