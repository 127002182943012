import PropTypes from "prop-types";
import React from "react";
import HeroHeader2 from "./Variants/HeroHeader2";

const variants = {
  HeroHeader2: HeroHeader2
};

const BlockVariant = block => {
  if (block.blockSettings) {
    if (block.blockSettings.variant) {
      if (variants[block.blockSettings.variant]) {
        const VariantComp = variants[block.blockSettings.variant];
        if (VariantComp) {
          return VariantComp;
        }
      }
    }
  }
  return false;
};
export default BlockVariant;
